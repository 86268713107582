import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ImageProvider } from "../common";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wochenLogo: {
      width: "40%",
      margin: "auto",
    },
    restLogos: {
      width: "70%",
      margin: "auto",
    },
    logoRow: {
      //index
      alignItems: "center",
    },
    aboutLogo: {
      //about
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 0,
        marginTop: 0,
        display: "flex",
        flexDirection: "unset",
      },
    },
    grid: {
      width: "100%",
    },
  })
);

const Logos: React.FC<{
  isIndex: boolean;
}> = ({ isIndex }) => {
  const { logoRow, aboutLogo, wochenLogo, restLogos, grid } = useStyles();
  const links = [
    "https://iwgr-ka.de/",
    "https://www.karlsruhe.de/",
    "https://copwatchffm.org/",
    "https://antidiskriminierung-ka.de/",
  ];
  const images = [
    "wochen-gegen-rassismus-logo.png",
    "kulturamt-karlsruhe-logo.png",
    "copwatch-logo.png",
    "logo_ADS_neu.jpg",
  ];

  return (
    <Grid container className={isIndex ? logoRow : aboutLogo} spacing={4}>
      {links.map((element, idx) => {
        const isLogoWochen = idx === 0;

        return (
          <Grid item xs={6} sm={6} md={3} lg={3} className={grid}>
            <a href={element}>
              <ImageProvider
                fileName={images[idx]}
                className={isLogoWochen ? wochenLogo : restLogos}
              />
            </a>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Logos;
