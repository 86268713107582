import React from "react";
import SEO from "../components/seo";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/core/styles";
import { TextRenderer } from "../components/common/text-renderer";
import { graphql } from "gatsby";
import Frame from "../components/common/frame";
import { theme } from "../components/common";
import { Hidden } from "@material-ui/core";
import Logos from "../components/common/logos";

type AboutProps = {
  pageContext: {
    slug: string;
  };
  data: {
    content: {
      nodes: {
        text: { raw: string };
        disclaimer: { raw: string };
        node_locale: string;
        title: string;
      }[];
    };
    about: { slug: string };
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      margin: "4rem",
      [theme.breakpoints.up("xs")]: {
        margin: "0.5rem",
        marginTop: "4rem",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "2rem",
        marginTop: "4rem",
      },
    },
    impressum: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "1rem",
        alignSelf: "flex-end",
      },
    },
    pageLayout: {
      display: "flex",
      height: "100vh",
      flexDirection: "row",
    },
  })
);

const AboutPage = ({
  data: { content, about },
  pageContext: { slug },
}: AboutProps) => {
  const [{ disclaimer, title, text }] = content.nodes;
  const classes = useStyles();
  return (
    <div className={classes.pageLayout}>
      <ThemeProvider theme={theme}>
        <Frame slug={slug} about={about} categories={[]} />
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Hidden mdUp>
                <Logos isIndex={false} />
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <h2>{title}</h2>
              <TextRenderer data={JSON.parse(text.raw)} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className={classes.impressum}
            >
              <h2>{"Impressum"}</h2>
              <TextRenderer data={JSON.parse(disclaimer.raw)} />
            </Grid>
          </Grid>
        </main>
      </ThemeProvider>
    </div>
  );
};

export const query = graphql`
  query($locale: String!) {
    content: allContentfulAboutPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        slug
        node_locale
        text {
          raw
        }
        disclaimer {
          raw
        }
      }
    }
    about: contentfulAboutPage(node_locale: { eq: $locale }) {
      slug
    }
  }
`;

export default AboutPage;
